body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.7;
}

.paragraph {
	font-size: $default-font-size;
	&--grey {
		color: $color-grey-dark;
	}

	&--blue {
		color: $color-blue;
	}

	&--sm {
		font-size: 1.4rem;
		@include respond(tab-port){
			font-size: 2rem;
		}
	}
}

.li-span {
	font-size: 1.4rem;
	@include respond(tab-port){
			font-size: 2rem;
		}
}

// Specifically for the navbar header
.heading-primary {
	text-transform: uppercase;
	color: $color-blue;
	font-weight: 400;
	font-size: 2.2rem;
	display: inline-block;
}


.heading-secondary {
	font-size: 5rem;
	text-transform: uppercase;
	font-weight: 400;

	&--custom {
		color: $color-white;
		font-weight: 600;
		font-size: $font-size-md;
	}

	&--grey {
		color: $color-grey-dark;
	}

	&--white {
		color: $color-white;
	}

	&--lg {
		font-size: 6.5rem;
	}

	&--bold {
		font-size: 650;
	}
}


.heading-tertiary {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 3.5rem;

	&--sm {
		font-size: 2.9rem;
	}

	&--xs {
		font-size: 2.9rem;
		line-height: normal;
	}

	&--grey {
		color: $color-grey-dark;
	}
}


// FOR HEADERS IN THE GRID ON FAQ PAGE
.heading-grid {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 2.5rem;
	color: $color-blue;
	margin-bottom: 3rem;
}


