.blog {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5%;
    background-color: $color-off-white;

    &__post {
        margin: 10px;
    }
}