// FOR COMPONENT: ChatHeader
.chat-header {
	height: 90vh;
	background-color: $color-yellow;
	padding: 5%;

	@include respond(tab-land) {
		height: 45%;
	}

	&__content {
		background-color: $color-white;
		height: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	&__text-box {
		margin: auto;
		width: 50%;
		@include respond(phone) {
			width: 90%;
		}
	}

	&__img-box {
		width: 50%;
//		background-image: url('../../assets/images/high-five.png');
		background-size: cover;
		background-position: left;
		&--img {
			width: 100%;
			max-height: 100%;
		}

		@include respond(tab-port) {
			display: none;
		}
	}
}

// FOR COMPONENT: ChatBox
.chat-box {
	height: 100%;
	margin-top: 10%;
	@include respond(tab-port) {
		height: 45%;
	}
}

// FOR COMPONENT: ChatComponent
.chat-component {
	display: flex;
	border: solid 1px $color-black;
	width: 70%;
	margin: 0 auto 5%;

	&__img-box {
		padding: 5%;
		border-right: 1px black solid;
		&--img {
			max-width: 100%;
			height: 30rem;
			border-radius: 100%;
		}
		@include respond(tab-port) {
			display: none;
		}
	}

	&__book-box {
		margin: auto;
		align-content: center;
		@include respond(tab-port) {
			padding: 5%;
		}
	}
}

// COMPONENT: ChatContact
#ycbmiframesoftwarebootcampacc {
	width: 90%;
	@include respond(phone) {
		max-width: 90vh;
	}
}