.input {
	font-size: 2rem;
	margin: 1rem;
	height: 34px;
	width: 100%;
	border-radius: 3px;
	border: 1px solid transparent;
	border-top: none;
	border-bottom: 1px solid #ddd;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
	padding: 5px;

	&--white {
		color: $color-white;
		border-bottom: $color-white solid 2px;
		&::placeholder {
			color: $color-white;
		}
	}

	@supports (-moz-appearance: none) {
		&--text-area {
			height: 10%;
		}
	}

	&--text-area {
		resize: none;
		height: 100px;
		@include respond(tab-port) {
		}
	}

	&:focus {
		outline: none;
	}

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

// CHECK BOX INPUT ON CONTACT COMPONENT
.checkbox {
	font-size: 1.4rem;
	color: $color-grey-dark;
	margin: 1rem 2.5rem;
	width: 90%;
	color: $color-grey-dark;

	@include respond(tab-port) {
		margin: 1rem 2rem;
	}
}
