// This fixes styling errors when using floats
@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet Portrait
900 - 1200px:    Tablet Landscape
[1200 - 1800] is where our normal styles apply
1800+:           Big Desktop

$breakpoint argument choices 
- phone
- tab-port
- tab-land
- big-desktop
1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {    // 600px
      @content;  
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {   // 900px
      @content;
    }
  }

  @if $breakpoint == tab-land {    // 1200px
    @media (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {  // 1800px
    @media (min-width: 112.5em) {
      @content;
    }
  }
}