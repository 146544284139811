// GRID
$grid-width: 114rem;
$gutter-vertical: 4rem;
$gutter-horizontal: 6rem;
$gutter-vertical-small: 6rem;

// COLORS
$color-white: #fff;
$color-grey: #b4b0b0;
$color-grey-dark: #464b55;
$color-blue: #526ba2;
$color-yellow: #f9d42c;
$color-black: #000;
$color-green: #28a745;
$color-red: #dc3545;
$color-off-white: #F3F7F7; 

// Font
$default-font-size: 1.6rem;
$font-size-md: 2rem
