// COMPONENT: Education
.education {
	height: 100%;
	width: 100%;
	background-color: $color-blue;
	color: $color-white;
	padding: 3% 10%;

	@include respond(tab-land){
		height: 45%;
	}

	&__text-box {
		margin-bottom: 3%;

		&--list {
			width: 80%;
			margin: 0 auto;
			text-align: left;
		}
	}
}
