.u-center-text {
	text-align: center;
}

.u-margin-bottom-2 {
	margin-bottom: 2rem !important;
}


.u-error-message {
	color: $color-red;
	font-size: 1.4rem;
}

.p-top-10 {
	padding-top: 10px;
}