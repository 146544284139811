// Styles for individual card
.student-card {
    position: relative;
    background-color: $color-white;
    height: 40rem;
    &__container {
        width: 35rem;
        box-shadow: 3px 10px 10px 5px $color-grey;
        margin: 5rem 1rem;
        &:hover {
            box-shadow: 5px 15px 15px 10px $color-grey;
            transition: all .2s;
        }
    }

    &__img-container {
        &--img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }
    }

    &__team-container {
        padding: .5rem 2.5rem;
    }

    &__team-member {
        &--name {
            font-weight: 600;
        }

        &__tooltip {
            width: 30rem;
            color: $color-white;
            padding: 1rem;
            font-size: $default-font-size;

            &--linkedin {
               background-color: $color-blue;
               font-weight: 700;
               font-size: $default-font-size; 
               color: $color-white;
               text-decoration: none;
               padding: 1rem;
               margin: .5rem;
               width: 12rem;
               display: block;
               cursor: pointer;
               &-none {
                   cursor: not-allowed;
                   background-color: $color-grey;
               }

            }
        }
    }

    &__link-to-docs {
        margin: 0 .5rem;
        position: absolute;
        top: 35.5rem;
        &__button {
            padding: 1rem;
            font-size: $default-font-size;
            cursor: pointer;
            transition: all .2s;

            &--description-exist {
                text-decoration: none;
                cursor: pointer;
                margin-right: 7rem;
                background-color: $color-yellow;
                &:hover {
                    background-color: darken($color-yellow, $amount: 10);
                }
            }

            &--description-none {
                margin-right: 7rem;
                background-color: $color-grey;
                cursor: not-allowed;
                font-size: $default-font-size;
            }

            &--deployment-exist {
                text-decoration: none;
                background-color: $color-blue;
                color: $color-white;
                cursor: pointer;
                &:hover {
                    background-color: darken($color-blue, $amount: 10);
                }
            }

            &--deployment-none {
                background-color: $color-grey;
                cursor: not-allowed;
                font-size: $default-font-size;
            }
        }
    }
}


// Styles for container that holds all the cards
.student-card-container {
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    width: 100%;
    background-color: $color-off-white;
}