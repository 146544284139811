.backdrop {
	width: 100%;
	height: 100%;
    z-index: 400;
    position: fixed;
	left: 0;
	top: 0;
    background-color: rgba(0, 0, 0, .5);
    animation: FadeIn .5s;
}
